<template>
    <div>
        <div v-if="formError" class="error-container">
            <p class="error">{{ formError }}</p>
        </div>
        <div>
            <button class="stc" @click="paymentInit()">STC</button>
        </div>
        <div v-if="paymentLoader">
            <Loading class="form_loader"></Loading>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    props: {
        gatewaydetail: {
            type: Object
        },
        paymentDetail: {
            type: Object
        }
    },
    data() {
        return {
            formError: null,
            paymentLoader: false
        }
    },
    watch: {
        formError(val) {
            if (val) {
                setTimeout(() => {
                this.formError = null;
                }, 5000);
            }
        }
    },
    created() {
        console.log("gatewaydetail", this.gatewaydetail);
        console.log("paymentdetail", this.paymentDetail);
    },
    methods: {
        ...mapActions(["paymentInitiation", "paymentConfirmation"]),
        paymentInit() {
            let currentDomain = window.location.origin;

            let payload = {
                gatewayid: this.gatewaydetail.gatewayid,
                params: {
                    amount: this.paymentDetail.detail.amount,
                    currency: this.paymentDetail.detail.currency,
                    devicetype: "WEB",
                    transactionpurpose: this.paymentDetail.transactionPurpose,
                    transactionmode: "CC",
                    availabilityid: this.paymentDetail.detail.availabilityset[0],
                    planid: this.paymentDetail.detail.planid
                }
            }

            payload.params.return_url = currentDomain + "/profile" + "?" + "planid=" + this.paymentDetail.detail.planid;

            console.log("payment init payload", payload);
            this.paymentLoader = true;

            this.paymentInitiation(payload)
                .then(response => {
                    this.paymentLoader = false;
                    console.log("payment init response", response);
                    if (!response.data.reason) {
                        window.open(response.data.referencedata.paymenturl, "_self");
                    } else {
                        this.formError = response.data.reason;
                    }
                })
                .catch(error => console.log("error", error));
        }
    },
    components: {
        Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue")
    }
}
</script>

<style lang='scss' scoped>
.error-container {
    border: 1px solid red;
    border-radius: 0.2rem;
    padding: 0.7rem;
    margin-bottom: 10px;
    .error {
      font-family: "Helvetica Neue Medium", sans-serif;
      font-size: 0.8rem;
      color: red;
      font-weight: 600;
      text-align: center;
    }
}
.stc{
    width: 100%;
    padding: 15px;
    margin-bottom: 15px;
    background: transparent;
    border: none;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 5px;
    cursor: pointer;
}
.stc:hover{
    background: #fff;
    color: #000;
}
</style>